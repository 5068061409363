import React from 'react';
import Image from './Image';
import Editor from './Editor';
import Injector from './Injector';
import Steps from './Steps';

const dynamicContent = (content) => {
  return content.map((content_item) => {
    switch (content_item.strapi_component) {
      case 'content.editor':
        return <Editor content={content_item} />;
      case 'content.image':
        return <Image content={content_item} />;
      case 'content.injector':
        return <Injector content={content_item} />;
      default:
        return;
    }
  });
};

export { Image, Editor, Injector, Steps, dynamicContent };

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const Injector = ({ content }) => {
  const { type, code } = content;

  useEffect(() => {
    if (type === 'javascript' && code) {
      const otaFunction = new Function(code);
      otaFunction();
    }
  }, []);

  if (type === 'html' && code) return <div dangerouslySetInnerHTML={{ __html: code }} />;
  return null;
};

Injector.propTypes = {
  content: PropTypes.shape({
    type: PropTypes.string,
    code: PropTypes.string,
  }),
};

export default Injector;

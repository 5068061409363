import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { StyledLink } from '../../../utils/ui';
import { getOriginalImageSrc } from '../../../utils/formatter';

const RelatedBlogsWrapper = styled.div`
  max-width: 645px;
  margin: 68px 0px;
  .relatedblogs-title {
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 37px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #292929;
    padding-bottom: 21px;
    border-bottom: 1px solid #d9d9d9;
  }
  .blog-item {
    display: flex;
    margin-top: 39px;
  }
  .blog-item-image {
    min-width: 203px;
    height: 153px;
    object-fit: cover;
  }
  .blog-item-content {
    margin-left: 16px;
  }
  .blog-item-title {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.03em;
    color: #292929;
    margin-block-end: 0;
  }
  .blog-item-description {
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 131.5%;
    display: flex;
    align-items: center;
    letter-spacing: 0.025em;
    color: #292929;
    margin: 12px 0px;
  }
  .blog-item-info {
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    color: #292929;
    margin-bottom: 18px;
  }
`;

const BlogItem = ({ blog }) => {
  const { slug, title, description, blog_thumbnail, updatedAt } = blog;
  return (
    <StyledLink className="blog-item" to={`/blog/${slug}`}>
      <img className="blog-item-image" src={getOriginalImageSrc(blog_thumbnail)} alt={title} />
      <div className="blog-item-content">
        <p className="blog-item-title">{title}</p>
        <p className="blog-item-description">{description}</p>
        <div className="blog-item-info">
          <span>Bình Khương Thôn</span>
          <span> - </span>
          <span>{dayjs(updatedAt).format('DD/MM/YYYY')}</span>
        </div>
      </div>
    </StyledLink>
  );
};

const RelatedBlogs = ({ relatedBlogs }) => {
  return (
    <RelatedBlogsWrapper>
      <p className="relatedblogs-title">Cùng Chuyên Mục</p>
      {relatedBlogs.map((blog) => (
        <BlogItem key={blog.id} blog={blog} />
      ))}
    </RelatedBlogsWrapper>
  );
};

BlogItem.propTypes = {
  blog: PropTypes.shape({
    slug: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    blog_thumbnail: PropTypes.object,
    updatedAt: PropTypes.string,
  }),
};

RelatedBlogs.propTypes = {
  relatedBlogs: PropTypes.array,
};

export default RelatedBlogs;

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import SiteLayout from '../components/SiteLayout';
import { MAX_WIDTH } from '../utils/constants';
import { Breadcrum } from '../components/common';
import { dynamicContent } from '../components/dynamicContent';
import RelatedBlogs from '../components/page/BlogPage/RelatedBlogs';
import { flattenStrapiData } from '../utils/formatter';

const BlogPostWrapper = styled.div`
  width: 100%;
  max-width: ${MAX_WIDTH};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 131.5%;
  letter-spacing: 0.025em;
  color: #292929;
  .post-title {
    font-family: Cormorant Infant;
    font-style: normal;
    font-weight: 600;
    font-size: 45px;
    line-height: 54px;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    color: #292929;
  }
  .post-description {
    margin: 11px 0px 21px 0px;
  }
  .post-info {
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    color: #292929;
    margin-bottom: 18px;
  }
`;

function Blog({ data: { strapiBlog: blogData, allStrapiBlog } }) {
  const LAYOUT_KEY = 'BLOG';
  const { id, slug, title, description, blog_thumbnail, blog_content, updatedAt } = blogData;
  const relatedBlogs = flattenStrapiData(allStrapiBlog);
  const breadcrumTree = [
    {
      id: 0,
      name: 'Trang chủ',
      slug: '/',
    },
    {
      id: 1,
      name: 'Blog',
      slug: '/blog',
    },
    {
      id: 2,
      name: title,
    },
  ];
  return (
    <SiteLayout activeKey={LAYOUT_KEY}>
      <BlogPostWrapper className="margin_prevent_navbar">
        <Breadcrum breadcrumTree={breadcrumTree} />
        <div className="post-title">{title}</div>
        <div className="post-description">{description}</div>
        <div className="post-info">
          <span>Bình Khương Thôn</span>
          <span> - </span>
          <span>{dayjs(updatedAt).format('DD/MM/YYYY')}</span>
        </div>
        {dynamicContent(blog_content)}
        {console.log(relatedBlogs)}
        {relatedBlogs?.length > 0 ? <RelatedBlogs relatedBlogs={relatedBlogs} /> : null}
      </BlogPostWrapper>
    </SiteLayout>
  );
}

Blog.propTypes = {
  data: PropTypes.shape({
    strapiBlog: PropTypes.shape({
      id: PropTypes.string,
      slug: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      blog_thumbnail: PropTypes.object,
      blog_content: PropTypes.object,
      updatedAt: PropTypes.string,
    }),
    allStrapiBlog: PropTypes.object,
  }),
};

export default Blog;

export const query = graphql`
  query Blogs($id: String!) {
    strapiBlog(id: { eq: $id }) {
      id
      slug
      title
      description
      blog_thumbnail {
        localFile {
          childImageSharp {
            original {
              src
            }
          }
        }
      }
      blog_content
      updatedAt
    }
    allStrapiBlog(filter: { id: { nin: [$id] } }, sort: { order: DESC, fields: published_at }) {
      edges {
        node {
          id
          slug
          title
          description
          blog_thumbnail {
            localFile {
              childImageSharp {
                original {
                  src
                }
              }
            }
          }
          updatedAt
        }
      }
    }
  }
`;

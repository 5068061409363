import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { getOriginalImageSrc } from '../../utils/formatter';

const StepsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(${(props) => props.stepCount}, 1fr);
  grid-template-rows: 1fr;
  padding-top: 146px;
  .step-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 24px 44px;
  }
  .step-item:not(:last-child) {
    border-right: 1px solid #a88c69;
  }
  .step-item-text {
    line-height: 162%;
    text-align: center;
    letter-spacing: 0.02em;
    color: #000000;
  }
`;

const Steps = ({ content: { steps_item } }) => {
  return (
    <StepsWrapper stepCount={steps_item?.length}>
      {steps_item.map((step) => {
        const { id, step: text, illustration } = step;
        return (
          <div key={id} className="step-item">
            <img className="step-item-img" src={getOriginalImageSrc(illustration)} alt={step} />
            <p className="step-item-text">{text}</p>
          </div>
        );
      })}
    </StepsWrapper>
  );
};

Steps.propTypes = {};

export default Steps;

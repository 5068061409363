import React from 'react';
import PropTypes from 'prop-types';

const Editor = ({ content }) => {
  const { text } = content;
  return <div dangerouslySetInnerHTML={{ __html: text }} />;
};

Editor.propTypes = {
  content: PropTypes.shape({
    text: PropTypes.string,
  }),
};

export default Editor;

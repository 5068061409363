import React from 'react';
import PropTypes from 'prop-types';
import { getOriginalImageSrc } from '../../utils/formatter';

const Image = ({ content }) => {
  const { image, caption } = content;
  return (
    <div>
      <img alt={caption} src={getOriginalImageSrc(image)} />
      <p>{caption}</p>
    </div>
  );
};

Image.propTypes = {
  content: PropTypes.shape({
    image: PropTypes.object,
    caption: PropTypes.string,
  }),
};

export default Image;
